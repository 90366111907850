<template>
  <div class="apply">
    <div class="apply-body">
      <div class="body-title">应用设置</div>
      <Form :label-width="160">
        <FormItem label="应用名称" class="max-width-900">
          <Input v-model="formData.name" placeholder="请输入应用名称" />
        </FormItem>
        <FormItem label="应用图标" class="max-width-900">
          <div style="display: flex">
            <div class="body-img" v-if="formData.thumb">
              <img :src="formData.thumb" alt="">
            </div>
            <Uploads :action="ApiUrl+'/adm/upload_img'" :data="{dir:'img_temp',type:'base64'}" style="display: inline-block;" @upload_succ="uploadSuccess" />
          </div>
        </FormItem>
        <FormItem label="SecretId" class="max-width-900">
          <span style="font-weight: 600;">{{ formData.secret_id }}</span>
        </FormItem>
        <FormItem label="SecretKey" class="max-width-900">
          <span style="font-weight: 600;">{{ formData.secret_key }}</span>
          <span style="margin-left: 16px; color: #FF5733; cursor: pointer;" @click="resetSecretKey">重置</span>
        </FormItem>
        <FormItem label="回调地址" class="max-width-900">
          <Input v-model="formData.dev_forward_url" placeholder="请输入回调地址">
            <template #prepend>
              <Select v-model="formData.forwardStart" style="width: 80px">
                <Option value="http">http://</Option>
                <Option value="https">https://</Option>
              </Select>
            </template>
          </Input>
        </FormItem>
        <FormItem label="IP白名单" class="max-width-900">
          <Input v-model="formData.ip_white_list" type="textarea" :autosize="{minRows: 5}" placeholder="请输入IP白名单"></Input>
        </FormItem>
      </Form>
    </div>
    <div class="apply-body">
      <div class="body-title">萤石云绑定</div>
      <Form :label-width="160">
        <FormItem label="萤石AppKey"  class="max-width-900">
          <Input v-model="monitoring.appkey" placeholder="请输入萤石AppKey" :disabled="!monitoring.editStatus" />
        </FormItem>
        <FormItem label="萤石AppSecret" class="max-width-900">
          <Input v-model="monitoring.secret" placeholder="请输入萤石AppSecret" :disabled="!monitoring.editStatus" />
        </FormItem>
        <FormItem>
          <Button type="primary" @click="editMonitoring">{{ !monitoring.editStatus && monitoring.appkey && monitoring.secret ? '更换' : '绑定' }}萤石云应用</Button>
          <Button type="error" style="margin-left: 12px;" @click="setMonitoring(1)">取消绑定萤石云</Button>
        </FormItem>
      </Form>
    </div>
    <div class="apply-bottom">
      <Button size="large" type="primary" @click="submit">提交</Button>
      <Button size="large" style="margin-left: 16px;" @click="$router.go(-1)">返回</Button>
    </div>
  </div>
</template>

<script>
import Uploads from '@/components/common/Uploads'
  export default {
    name: 'apply',
    components: {
      Uploads
    },
    data () {
      return {
        ApiUrl: this.ApiUrl,
        formData: {
          id: null,
          name: '',
          thumb: '',
          secret_id: '',
          secret_key: '',
          dev_forward_url: '',
          forwardStart: 'https',
          ip_white_list: ''
        },
        monitoring: {
          editStatus: false,
          appkey: '',
          secret: ''
        }
      }
    },
    created() {
      if (this.$route.query.secretId) {
        this.secretId = this.$route.query.secretId
        this.init()
      }
    },
    methods: {
      init () {
        const _this = this;
        this.requestApi('/adm/get_external_apply_info', {secretId: this.secretId}).then(res => {
          if (res.status === 1) {
            res.data.data.forwardStart = res.data.data.dev_forward_url.split('://')[0]
            res.data.data.dev_forward_url = res.data.data.dev_forward_url.split('://')[1]
            _this.formData = res.data.data;
            if (res.data.monitoring !== null) {
              res.data.monitoring.editStatus = false
              _this.monitoring = res.data.monitoring
            } else {
              _this.monitoring.editStatus = true
            }
          } else {
            _this.alertErr(res.msg)
            _this.$router.go(-1)
          }
        })
      },
      // 提交
      submit() {
        const _this = this;
        let data = JSON.parse(JSON.stringify(this.formData))
        data.dev_forward_url = data.forwardStart + '://' + data.dev_forward_url
        this.$delete(data, 'forwardStart')
        this.requestApi('/adm/add_external_apply', {data}).then(res => {
          if (res.status === 1) {
            _this.alertSucc('提交成功');
            _this.$router.go(-1)
          } else {
            _this.alertErr(res.msg)
          }
        })
      },
      resetSecretKey() {
        const _this = this
        this.$Modal.confirm({
          title: '提示',
          content: '确定重置密钥？重置后之前的密钥将无法使用！',
          onOk: () => {
            let data = JSON.parse(JSON.stringify(_this.formData))
            data.dev_forward_url = data.forwardStart + '://' + data.dev_forward_url
            _this.$delete(data, 'forwardStart')
            _this.requestApi('/adm/add_external_apply', {data, resetSecretKey: true}).then(res => {
              if (res.status === 1) {
                _this.alertSucc('重置成功');
                _this.formData.secret_key = res.data.secret_key
              }
            })
          }
        })
      },
      // 上传图片成功
      uploadSuccess(res) {
        if (res.status === 1) {
          res.pic_url = res.pic_url.indexOf('/uploads') > -1 ? this.ImgUrl + res.pic_url : 'https://' + res.pic_url
          this.formData.thumb = res.pic_url;
        } else {
          this.alertErr(res.msg)
        }
      },
      editMonitoring () {
        if (this.monitoring.editStatus) {
          this.setMonitoring(0)
        } else {
          this.monitoring.editStatus = true
        }
      },
      setMonitoring (type) {
        const _this = this
        let data = {
          appkey: this.monitoring.appkey,
          secret: this.monitoring.secret,
          cloud_apply_id: this.formData.id
        }
        this.requestApi('/adm/set_monitoring_app_info',{data,type}).then(res => {
          if (res.status === 1) {
            _this.alertSucc('操作成功')
            if (type === 1) {
              _this.monitoring.editStatus = true
              _this.monitoring.appkey = ''
              _this.monitoring.secret = ''
            } else {
              _this.monitoring.editStatus = false
            }
          } else {
            _this.alertErr(res.msg)
          }
        })
      }
    }
  }
</script>

<style scoped>
  .apply-body {
    padding: 32px;
    background: #fff;
    border-radius: 12px;
    margin-bottom: 16px;
  }

  .apply-body .body-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    padding: 0 16px;
    border-left: 4px solid #42A4FF;
    line-height: 18px;
  }

  .body-img {
    width: 75px;
    height: 75px;
    margin-right: 12px;
  }

  .body-img img {
    width: 100%;
    height: 100%;
  }

  /deep/ .ivu-form-item {
    margin-bottom: 24px !important;
  }

  .max-width-900 {
    max-width: 900px;
  }

  .apply-bottom {
    width: 100%;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    box-shadow: 0 -4px 12px 6px rgba(0, 0, 0, 0.1);
  }
</style>
